    html{
        margin: 0px;
        padding: 0px;
    }
    * {
        font-family: Poppins!important;
    }
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }

  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }

  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }

  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }

  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }

  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }

  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }

  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }

  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }

  .bnbguard-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bnbguard-menu-item{
    padding: 5px 0px;
    background-color: #002d62!important;
    color: #a6b5c8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1em;
    line-height: 0.8em;
  }
  .bnbguard-menu-item-active{
    padding: 5px 0px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    line-height: 0.8em;
  }
  .bnbguard-menu-item small,
  .bnbguard-menu-item-active small{
    font-size: 0.4em;
  }
  /*
  .recharts-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .recharts-legend-wrapper{
    position: absolute; width: auto; left: 0px; right: 0px;bottom: 0px; border: 1px solid red;display:flex;justify-content:center;align-items:center;
  }
  */

.ant-layout-slider{
    background-color: #002d62!important; /* #0a2327; */
}
.layout-slider {
    overflow: auto;
    height: 100vh;
    /*position: fixed;
    left: 0;
    top: 80;
    padding-top: 30px;
    padding-bottom: 16px;*/
    background-color: #002d62!important; /* #0a2327!important; */
}

.bnbguard-footer{
    color: white;
    background-color: #002d62!important;
    padding: 12px 0px;
}

.switch-account-select{
    width: 100%;
}

.global-header{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px; /* 0px 0px 0px 16px; */
    margin: 0px;
    width: 100%;
    background-color: #fafafa; /* #001529; */
    /* height: 64px; */

    > .global-header-logo-holder{
        flex: 1;
        max-width: 60px;
        background-color: #002d62!important; /* #0a2327; */ /*#001529;*/
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        > .global-header-logo-desktop{
            display: none;
        }
        > .global-header-logo-mobile{
            width: 25px;
            height: 32px;
            display: flex;
        }
    
    }
    
    > .global-header-heading{
        display: none;
    }

    > .global-header-account-menu-holder{
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .ant-select-selector{
            border: none!important;
        }
        > .account-menu-select-council{
            flex: 1;
            margin: 0px;
            display: inline-block;
            width: 100%;
            max-width: auto; /* 200px; */
            text-transform: capitalize;
            border: none;
            box-shadow: none;
        }
        > .account-menu-user-holder{
            flex: 0;
            display: flex;
            white-space: nowrap;
            justify-content: flex-end;
            align-items: center;
            color: #fff;
            padding: 0px 20px 0px 3px;
            min-width: auto; /* 92px; */ /* 165px; */
            > .account-menu-user-name{
              margin-left: 4px;              
              cursor: pointer;
              background-color: #002d62;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              width: 46px;
              height: 46px;
            }
            > .account-menu-user-image{
                width: 20px;
                margin: 0px;
                border-radius: 50%;
                cursor: pointer;
                background-color: #002d62;
            }
        }
    }
}

.page-content-holder{
    position:relative;
    height:100%;
    background-color: #fafafa;
}
.page-content-scroll{
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow-y:auto;
    display: flex;
    flex-direction: column;
}

.page-content-row{
    flex: 1;
    position:static;
    padding: 0px;
    width:100%;
}

.search-input-holder{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px;
    font-weight: 500;
    z-index: 2;
}

.search-input{
    min-width: 260px;
}

.back-button{
    flex:0;
    border:none!important;
    padding:0px;
    margin:0px;
    font-size: 0.9em!important;
    font-weight: 500;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    color:rgba(0, 0, 0, 0.85);
}

.back-button:hover{
    color: rgba(0, 0, 0, 0.85)!important;
}

.page-header span{
    font-weight: bold;
}

.page-header{
    font-size: 0.75em;
    font-weight: 500;
    padding: 20px 10px 15px 10px;
    text-transform: capitalize;
    background-color:#fafafa;
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-items:center;
    margin-bottom:10px;
    margin-top: -1px;
    font-size: 1.3em;
}

.page-header-title{
    flex: 1;
    width: 100%;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    order: 0!important;
    padding: 5px 0px;
    text-transform: capitalize;
}
.page-header-results{
    flex: 1;
    order: 4!important;
    text-align: center;
    font-size: 0.8em;
}

.search-area{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 500;
    z-index: 2;
    max-width: 300px;
    order: 1!important;
}

.search-input-holder{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px;
    font-weight: 500;
    z-index: 2;
    position: relative;
    min-height: 40px;

}

.search-input-holder .search-icon{
    flex: 0;
    border-radius: 50%;
    color: white;
    background-color: #002d62;
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    z-index: 1;
    min-width: 40px;
    min-height: 40px;
}

.search-input{
    /* min-width: 300px;*/
    border: none!important; /* 2px solid #002d62!important; */
    background-color: #f8f8f8!important;
    position: absolute;
    top: 0px!important;
    right: 10px!important;
    bottom: 0px;
    left: 0px;
    padding: 9px 0px!important;
    padding-left: 50px!important;
    border-radius: 20px!important;
}


.page-header h2{
    flex: 1;
    font-size: 1.1em;
    font-weight: 500;
    padding: 7px 0px 0px 0px;
    text-align:left;
}

.page-header h2.no-back-button{
    font-size: 1.1em;
    font-weight: 500;
    text-align: left;
}

.page-header h3{
    padding: 0px 10px;
}

.page-table-holder{
    min-height:1000px!important;
    display:flex;
    flex-direction:column;
    justify-content:stretch;
    align-items:stretch;
}

.image-column{
    max-width: none;
    width: 100%;
    text-align: center;
    margin: auto;
}

.empty-image-link{
    font-size: 4em;
    padding: 0px;
    display: flex;
    color: #ccc;
    justify-content: center;
    align-items: center;
    width:auto!important;
    height: auto!important;
}


@media (min-width: 650px){

    .global-header{

        > .global-header-logo-holder{
            /* max-width: 183px; */
            max-width: 114px;
            display: flex;
            justify-content: center;
            align-items: center;

            > .global-header-logo-mobile{
                display: none;
            }

            > .global-header-logo-desktop{
                /*
                width: 146px;
                height: 30px;
                */
                width: 100%;
                max-width: 50%;
                display: flex;
            }
        }

        > .global-header-account-menu-holder{

            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            > .account-menu-select-council{
                flex: 1;
                margin: 0px;
                display: inline-block;
                width: 100%;
                max-width: 300px;
                text-transform: capitalize;
            }

            > .account-menu-user-holder{
                flex: 0;
                justify-content: flex-end;
                align-items: center;
                padding: 0px 50px 0px 0px;
                font-size: 1.2em;
                /* min-width: 275px; */
                padding: 0px 70px 0px 0px;            
                > .account-menu-user-name{
                    margin-left: 4px;
                    cursor: pointer;
                    background-color: #244b72;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    width: 46px;
                    height: 46px;
                }
                > div {
                    margin-left: 8px;
                    cursor: pointer;
                }

                > .account-menu-user-image{
                    width: 30px;
                    margin: 0px;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

        }

    }

    .page-header{
        flex-direction: column;
        /* padding: 5px 10px 5px 10px; */
        padding: 20px 10px 15px 10px;
    }

    .page-header-title{
        flex: 0;
        white-space: nowrap;
        width: auto;
        max-width: 33%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        order: 0!important;
    }
    .search-area{
        flex: 1;
        order: 3!important;
    }
    .page-header-results{
        flex: 1;
        order: 2!important;
    }

    .image-column{
        max-width: 100px;
    }

}

@media (min-width: 839px){
    .global-header-heading{
        display: none;
        color: white;
        font-size: 1.2em;
        font-weight: 500;
        flex-grow: 0.9;
        padding: 0px 0px 0px 15px;
    
    }    
    .page-content-scroll{
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        /* overflow: hidden; */
    }
    .page-content-row{
        flex: 1;
        position:relative;
    }

    .back-button{
        flex:0;
        font-size: 1.1em;
    }

    .page-header{
        font-size: 1.2em;
        padding: 20px 10px 15px 30px;
        text-transform: capitalize;
        /* background-color:white; */
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        align-items:center;
        margin-bottom:10px;
    }


    .page-header h2.no-back-button{
        font-size: 1.3em;
        font-weight: 500;
        text-align: left;
    }

    .page-header span{
        font-size: 1.3em;
        font-weight: 500;
    }

    .search-area{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-weight: 500;
        z-index: 2;
        max-width: 300px;
    }

    .search-input-holder{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 0px;
        font-weight: 500;
        z-index: 2;
        position: relative;
    }

    .search-input-holder .search-icon{
        flex: 0;
        min-width: 40px;
        min-height: 40px;
        border-radius: 50%;
        color: white;
        background-color: #002d62;
        display:flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0px;
        z-index: 1;
    }
    
    .search-input{
        /* min-width: 300px;*/
        border: none!important; /* 2px solid #002d62!important; */
        background-color: #efefef!important;
        position: absolute;
        top: 0px!important;
        right: 10px!important;
        bottom: 0px;
        left: 0px;
        padding: 9px 0px!important;
        padding-left: 50px!important;
        border-radius: 20px!important;
    }
}
.summary{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:stretch;
}
.summary .graph-holder{
    flex:1;
    margin:7px;
    padding:20px 25px;
    background-color:#fcfcfc;
    box-shadow:0px 0px 2px rgba(0,0,0,0.4);
    border-radius:5px;
}
.summary .graph-title{
    padding:0px 0px 5px 0px;
    margin:-5px 0px 5px 0px;
    font-weight:500;
    font-size:1.1em;
    border-bottom:1px solid #efefef;
}
.summary .summary-text{
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:stretch;
    font-size:1.1em;
    font-weight:bold;
    padding:0px 0px 15px 0px;
}

.conic-gradient{
    /*
    width: 150px;
    height: 150px;
    */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;    
    /* position: absolute; */ 
    /* background: conic-gradient(from -30deg at center, blue 0deg, white 400deg); */
}

@media (min-width:1300px){
    .summary{
        display:flex;
        flex-direction:row;
        justify-content:space-evenly;
        align-items:stretch;
    }
     .summary .summary-text{
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:stretch;
        font-weight:bold;
        padding:0px 0px 15px 0px;
    }

}