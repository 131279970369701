/*
    overwrite ant layout
*/

.ant-layout{
  margin-top: 0px!important;
  background-color: #fafafa!important;
}
.ant-layout-content{
  padding:0px!important;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #002d62!important; /* #0a2327; */
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
  justify-content: center;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #002d62!important; /* #0a2327; */
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: none; */
}

.ant-btn{
  box-shadow: none;
}

@media (min-width: 839px) {

  .ant-layout-content{
      padding: 0px!important;
      /* padding:24px!important; */
  }

  .ant-layout-header h1{
      display:flex;
  }
}

.ant-layout-header h1{
  display:none;
}

.capitalize {
  text-transform: capitalize;
}

.ant-descriptions-item-container .ant-descriptions-item-label {
  font-weight: 600;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor;
}

.ant-carousel .slick-prev {
  left: 6px;
  z-index: 1;
}

.ant-carousel .slick-next {
  right: 6px;
  z-index: 1;
}

.ant-statistic-title {
  margin-bottom: 0;
  font-size: 12px;
}

.ant-statistic-content {
  font-size: 18px;
}

.ant-statistic-content-suffix {
  margin-left: 0px;
  font-size: 14px;
}

.ant-page-header .ant-card-small > .ant-card-head {
  padding-left: 0;
  padding-right: 0;
}

.ant-page-header .ant-card-small > .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}

.ant-drawer-header {
  border-radius: 0;
  min-height: 64px;
  padding: 0 12px;
  background: #155c9f;
  border-bottom: none;
  display: flex;
  align-items: center;
}

.ant-drawer-title {
  text-transform: capitalize;
  color: #fff;
  font-weight: 300;
  font-size: 21px;
}

.ant-drawer-close {
  min-height: 64px;
  color: #fff;
}

.ant-drawer-content {
  background: hsla(0, 0%, 100%, 0.7);
}

.ant-drawer-body {
  padding: 0.5em;
}

.ant-drawer-body .ant-collapse.ant-collapse-borderless {
  background: none;
}

.ant-drawer-body .ant-collapse > .ant-collapse-item {
  border-bottom: none;
  margin: 2px 0;
}

.ant-drawer-body .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;
  color: #1890ff;
}

.ant-drawer-body .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-list-item-meta-title {
  margin-bottom: 0;
  font-weight: 300;
}

.ant-dropdown-menu-title-content {
  text-transform: capitalize;
}

.ant-select-item-option-content {
  text-transform: capitalize;
}

.ant-list-vertical .ant-list-items .ant-list-items .ant-list-item-meta-title {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5715;
  margin-bottom: 0;
}

.ant-list-lg .ant-list-items .ant-list-items .ant-list-item {
  padding: 12px 0;
}

.ant-list-vertical .ant-list-items .ant-list-items .ant-list-item-meta {
  margin-bottom: 0;
}
body{
	height:100vh;
  overflow-y:hidden;
}
footer{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    /* background-color: #f0f2f5; */
    position: fixed;
    bottom: 0px;
    left: 0px;
}
.ant-table-pagination{
  z-index:0;
  padding:0px 10px;
}

.ant-table.ant-table-small{
  font-size:0.8em;
}

/*
	WP - New Table Styles (flex and responsive)
*/
/*
.listings-suburbs-page{
  position:relative;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:stretch;
  align-items:stretch;
}
.suburbs-listings-table-holder{
  flex:1;
  position:relative;
}
*/
.suburbs-listings-table{
  border:none;
}

@media (min-width: 839px){

    .ant-table.ant-table-small{
      font-size:1em;
    }

    .suburbs-listings-table{
        border: none; /* 1px solid #efefef; */
        box-shadow:0px 0px 3px rgba(0,0,0,0.1);
        background-color: white;
        padding: 1px;
        margin: 5px 10px; /* 8px 7px; */
        border-radius: 15px;
        overflow:hidden;
        position:absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
      
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        overflow-y:hidden;
      
      }

      .suburbs-listings-table .ant-spin-nested-loading{
        flex:1;
        margin:0px;
      
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        overflow-y:hidden;
      
      }
      
      .suburbs-listings-table .ant-spin-container{
        flex:1;
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
      }
      
      .suburbs-listings-table .ant-spin-container ul{
        flex:0;
      }
      .suburbs-listings-table .ant-spin-container .ant-table{
        flex:1;
        flex-grow:1;
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        margin:0px;
        overflow-y:hidden!important;
        
      }
      
      .suburbs-listings-table .ant-spin-container .ant-table .ant-table-container{
        flex:1;
        flex-grow:1;
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        margin:0px;
        overflow:hidden!important;
        position: relative;
        
      }
      .suburbs-listings-table .ant-spin-container .ant-table .ant-table-container .ant-table-body{
        flex:1;
        flex-grow:1;
      
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        margin:40px 0px 0px 0px;
      
        position:absolute;
        top:0px;
        bottom:0px;
        left:0px;
        right:0px;
      
        flex:1;
        max-height:none!important;
        overflow:auto!important;
      }
      
      .suburbs-listings-table .ant-spin-container .ant-table .ant-table-container .ant-table-content{
        flex:1;
        flex-grow:1;
      
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        margin:30px 0px 0px 0px;
        position:absolute;
        top:0px;
        bottom:0px;
        left:0px;
        right:0px;
      
        flex:1;
        max-height:none!important;
        overflow:auto!important;
      }
      .suburbs-listings-table .ant-spin-container .ant-table .ant-table-container .ant-table-content{
        margin:0px 0px 0px 0px;
      }
      .suburbs-listings-table .ant-layout-content .ant-page-header{
        height: 10%;
        max-height:80px;
      }
      

}

